<template>
  <div class="no-avalible-data-wrapper">
    <div class="placeholder-img-wrapper" v-lazy-background :lazy-background="placeholderImg[placeholderClassName]" :class="[placeholderClassName]" />
    <div class="tip-text text-describe" v-html="tipText " />
    <div v-if="showRetryBtn" class="operate-container">
      <div class="button button-small" :class="className" @click="retry" v-html="retryBtnText" />
    </div>
  </div>
</template>

<script>
const noDataImg = require('assets/images/error/no-available-data.png');
const errorImg = require('assets/images/error/bg-error.png');

export default {
  props: {
    placeholderClassName: {
      default: '',
      type: String,
    },
    tipText: {
      default: 'No Available Data',
      type: String,
    },
    showRetryBtn: {
      default: false,
      type: Boolean,
    },
    retryBtnText: {
      default: 'Retry',
      type: String,
    },
    className: {
      default: 'button-grey',
      type: String,
    },
  },
  data() {
    return {
      placeholderImg: {
        'no-avalible-data-img': noDataImg,
        'system-error-img': errorImg,
      },
    }
  },
  methods: {
    retry() {
      this.$emit('retry')
    },
  },
}
</script>

<style lang="scss" scoped>
.no-avalible-data-wrapper {
  width: 100%;

  .placeholder-img-wrapper {
    margin: 0 auto;
    width: px2vw(300);
    height: px2vw(300);

    &.no-avalible-data-img {
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
    }

    &.system-error-img {
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
    }
  }

  .placeholder-img {
    width: 100%;
    height: 100%;
  }

  .tip-text {
    margin: px2vw(10) auto 0;
    width: px2vw(520);
    text-align: center;
    color: #787878;
    font-family: PingFangSC-Regular, PingFang SC, Arial, Helvetica, sans-serif;
    font-weight: 400;
    line-height: 17px;
    font-size: 12px;
  }

  .operate-container {
    margin-top: px2vw(40);
    text-align: center;

    .button {
      display: inline-block;
      margin: 0 auto;
    }
    // .button-grey{
    //   background: rgba(222,226,231,.3);
    //   colo;
    // }
    // .button-outline{
    //   color: #fff;
    //   font-weight:600;
    //   background:linear-gradient(45deg,rgba(255,213,24,1) 0%,rgba(255,170,10,1) 100%);
    // }
  }
}
</style>
